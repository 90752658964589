<template>
  <div>
    <v-row>
      <v-col cols="12" class="d-flex justify-space-between align-center">
        <h2 class="main-title">{{ $t("new.completedOrders") }}</h2>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="pt-0">
        <total :total="COUNT" />
      </v-col>
    </v-row>
    <table-viewing-arhive
      :users_data="ORDERLIST"
      :headers="ORDERFIELDS"
      @onPagination="
        getFilterData(objectProfile, objectVehicle, sortOptions, ...arguments)
      "
      @sortBy="getFilterData(objectProfile, objectVehicle, ...arguments, page)"
      :page="page"
      :load="load"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Total from "@/components/Total";
import TableViewingArhive from "@/components/TableViewingArhive.vue";

export default {
  components: { Total, TableViewingArhive },
  data: () => {
    return {
      page: 1,
      objectProfile: undefined,
      objectVehicle: undefined,
      sortOptions: undefined,
      inProgress: false,
      load: true
    };
  },
  computed: {
    ...mapGetters(["ORDERLIST", "ORDERFIELDS", "COUNT"])
  },
  methods: {
    ...mapActions(["getOrderList"]),
    getFilterData(
      profile = this.objectProfile,
      vehicle = this.objectVehicle,
      sort = { ordering_field: "id", desc: true },
      page = this.page,
      inProgress = this.inProgress
    ) {
      this.objectProfile = profile;
      this.objectVehicle = vehicle;
      this.sortOptions = sort;
      this.page = page;
      this.inProgress = inProgress;

      let desc =
        this.sortOptions.desc.length > 0 && this.sortOptions.desc[0] ? "" : "-";
      let arg = "?";
      this.page ? (arg = arg + `page=${this.page}`) : "";
      this.objectProfile
        ? (arg = arg + `&driver=${this.objectProfile.id}`)
        : "";
      this.objectVehicle
        ? (arg = arg + `&vehicle=${this.objectVehicle.id}`)
        : "";
      this.sortOptions.ordering_field != ""
        ? (arg =
            arg +
            `${
              this.objectProfile || this.objectVehicle || this.page ? "&" : ""
            }ordering=${desc}${this.sortOptions.ordering_field}`)
        : "";
      this.inProgress !== undefined
        ? (arg = arg + `&finish_fact_dt__isnull=${inProgress}`)
        : "";
      this.getOrderList({ args: arg });
    },
    getOrder(page) {
      this.page = page;
      this.getOrderList({ args: `?finish_fact_dt__isnull=false&page=${page}` });
    }
  },
  mounted() {
    setTimeout(() => {
      this.load = false;
      this.getFilterData();
    }, 1000);
  }
};
</script>

<style lang="scss" scoped></style>
