<template>
  <div>
    <v-data-table
      :headers="visibleHeaders"
      :items="filteredData"
      hide-default-footer
      class="elevation-3 table table__custom"
      :items-per-page="itemsPerPage"
      :options.sync="options"
      :custom-sort="customSort"
      :loading="load"
      sort-by="id"
    >
      <template v-slot:[`item.state`]="{ item }">
        <v-chip color="red" class="chip" dark v-if="item.state == false">
          {{ $t("settings.finished") }}
        </v-chip>
      </template>

      <template v-slot:[`header.actions`]>
        <p class="search_category" @click.stop="show = !show">
          <v-icon color="#cbd3e9" small>search</v-icon>
          <span>{{ $t("searchCat") }}</span>
        </p>
      </template>

      <template v-slot:[`body.prepend`] v-if="headers">
        <tr v-if="show">
          <td v-for="item in visibleHeaders" :key="`search_${item.value}`">
            <v-text-field
              class="table-search"
              v-if="item.value !== 'actions'"
              height="30"
              append-icon="mdi-magnify"
              hide-details
              background-color="#fff"
              rounded
              single-line
              @input="search($event, item.value)"
            ></v-text-field>
            <v-icon v-else small @click="show = false">close</v-icon>
          </td>
        </tr>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-btn
          fab
          x-small
          tag="router-link"
          :to="`/orderArhive/${item.id}`"
          icon
          center
        >
          <v-icon small> mdi-eye </v-icon>
        </v-btn>
      </template>

      <template v-slot:no-data>
        <p class="nodata">{{ $t("title.nodata") }}</p>
      </template>
    </v-data-table>
    <div class="pagination-block" v-if="pageCounter > 1">
      <v-pagination
        v-model="_page"
        :length="pageCounter"
        :total-visible="7"
        circle
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data: () => ({
    itemsPerPage: 10,
    searchFor: {},
    searchValue: "",
    show: false,
    key: "",
    ifSerching: false,
    options: {}
  }),
  components: {},
  props: {
    users_data: {},
    title: {},
    toEditItem: {
      type: Object,
      default: null
    },
    directory: {
      type: String,
      default: ""
    },
    clickLoop: {},
    headers: {
      type: Array,
      default: () => []
    },
    page: {
      type: Number,
      default: 1
    },
    load: {
      type: Boolean,
      default: true
    }
  },

  computed: {
    _page: {
      get() {
        return this.page;
      },
      set(page) {
        this.$emit("onPagination", page);
      }
    },
    pageCounter() {
      return Math.ceil(this.COUNT / 10);
    },
    filteredData() {
      let datas = this.users_data.map(function(item) {
        let newItem = {
          ...item
        };
        const keyValues = Object.keys(newItem).map(key => {
          if (newItem[key] !== null && typeof newItem[key] === "object") {
            newItem[key] = item[key].caption;
          }
          // here the date converts to string
          if (newItem[key] !== null && key === "connected_dt") {
            let date = new Date(item[key] * 1000);
            newItem[key] = date.toString().substring(0, 21);
          }
          // if (newItem[key] !== null && key === "state") {
          //   newItem[key] = "inActive";
          //   newItem[key];
          // }
          return newItem;
        });
        return Object.assign({}, ...keyValues);
      });
      if (!this.ifSerching) {
        return datas;
      } else {
        return datas.filter(item => {
          if (item[this.key]) {
            return (
              item[this.key]
                .toString()
                .toLowerCase()
                .indexOf(this.searchValue.toLowerCase()) !== -1
            );
          }
        });
      }
    },

    visibleHeaders() {
      return this.headers.filter(header => header.visible);
    },
    ...mapGetters(["ROLES", "ISUSER", "ISADMINCOMPANY", "ISADMIN", "COUNT"])
  },

  methods: {
    customSort(items) {
      return items;
    },
    sortBy() {
      const { sortBy, sortDesc } = this.options;
      this.$emit("sortBy", {
        ordering_field: sortBy,
        desc: sortDesc
      });
    },
    search(val, key) {
      this.searchValue = val;
      this.key = key;
      this.ifSerching = true;
    },

    verificationRole() {
      if (
        this.ISADMIN ||
        (this.ISADMINCOMPANY && this.ROLES.seller) ||
        (this.ISUSER && this.ROLES.seller) ||
        (this.ISADMINCOMPANY && this.directory.match("company"))
      ) {
        return true;
      } else return false;
    }
  },
  watch: {
    options: {
      handler() {
        this.sortBy();
      },
      deep: true
    }
  }
};
</script>

<style lang="scss" scoped>
.v-text-field {
  margin: 0;
  padding: 0;
}

.btn__row {
  display: flex;
  justify-content: flex-end;

  &:last-child {
    margin-top: 10px;
  }
}

.pagination-block {
  margin-top: 40px;
}

.table-search {
  max-width: 200px;
}

.nodata {
  margin: 0;
}
</style>
